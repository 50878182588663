<ng-container *ngIf="isBrowser">
  <p-dialog *ngIf="isBrowser"
    [modal]="true"
    [(visible)]="showWindow"
    (onHide)="onClose.emit(false)"
    styleClass="h-full w-full md:w-3/4 p-0"
  >
    <ng-template pTemplate="header">
      <div class="w-full flex justify-center items-center gap-4 px-4 py-2">
        <span
          *ngIf="!modoEditar"
          class="w-full text-2xl text-center font-bold white-space-nowrap"
        >
          {{ fv.texto_titulo }}
        </span>

        <text-field
          *ngIf="modoEditar"
          [control]="fc.texto_titulo"
          inputId="texto_titulo"
          type="text"
          class="w-full bg-transparent border-0 px-0 py-1"
          label="Título"
          [required]="fc.texto_titulo.hasValidator(requiredValidator)"
        />

        <button-template
          *ngIf="!readOnly"
          buttonStyle="outline"
          [isLoading]="isLoading"
          [label]="modoEditar ? 'Visualizar' : 'Editar'"
          [icon]="
            modoEditar
              ? 'icon-[mdi--account-view-outline] w-5 h-5'
              : 'icon-[mdi--edit] w-5 h-5'
          "
          [tooltip]="
            modoEditar
              ? 'Mostrar visão do usuário'
              : 'Voce só está vendo esse botão porque é um Admim do sistema'
          "
          (onClick)="ativarModoEditar()"
        ></button-template>
      </div>
    </ng-template>

    @if(isBrowser) {

    <quill-editor
      *ngIf="!modoEditar"
      [formControl]="fc.texto_sistema"
      class="w-full cursor-pointer"
      [readOnly]="true"
      [modules]="{ toolbar: false }"
    ></quill-editor>

    <!-- <div *ngIf="!modoEditar && fv.texto_sistema" [innerHTML]="fv.texto_sistema | ajustaQuebraLinha"></div> -->

    <quill-editor
      *ngIf="modoEditar"
      [formControl]="fc.texto_sistema"
      placeholder="Adicione aqui o texto desejado"
      class="size-full"
      [modules]="modules"
    ></quill-editor>

    }

    <ng-template pTemplate="footer">
      <div class="w-full flex justify-end items-center gap-5 px-4 py-1">
        <!-- DADOS DE CRIAÇÃO E ATUALIZAÇÃO -->

        <variaves-regulamento *ngIf="modoEditar"></variaves-regulamento>

        <div
          *ngIf="modoEditar"
          class="grid grid-cols-1 md:grid-cols-2 justify-center mx-auto items-center gap-3"
        >
          <div
            class="w-full flex flex-col justify-center items-center border rounded-lg text-gray-600"
            *ngIf="dtCreated"
          >
            <div
              class="w-full text-center bg-gray-300 text-xs font-normal rounded-tl rounded-tr"
            >
              Cadastro realizado
            </div>
            <div class="text-xs mx-2 text-gray-400">
              <strong>Em: </strong>{{ dtCreated | datetimeBr }}
            </div>
          </div>

          <div
            class="w-full flex flex-col justify-center items-center border rounded-lg text-gray-600"
            *ngIf="dtUpdated"
          >
            <div
              class="w-full text-center bg-gray-300 text-xs font-normal rounded-tl rounded-tr"
            >
              Atualização realizada
            </div>
            <div class="text-xs mx-2 text-gray-400">
              <strong>Em: </strong>{{ dtUpdated | datetimeBr }}
            </div>
          </div>
        </div>

        <button-template
          buttonStyle="outline"
          label="Fechar"
          [isLoading]="isLoading"
          icon="icon-[mdi--close] text-xl"
          (onClick)="onClose.emit(false)"
        ></button-template>

        <button-template
          *ngIf="modoEditar"
          buttonStyle="filled"
          label="Salvar"
          icon="icon-[mdi--content-save-outline] text-xl"
          (onClick)="saveTextoSistema()"
          [isLoading]="isLoading"
          [disabled]="formTextoSistema.invalid"
        ></button-template>
      </div>
    </ng-template>
  </p-dialog>
</ng-container>

