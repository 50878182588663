import {
  CommonModule,
  isPlatformBrowser,
  isPlatformServer,
} from '@angular/common';
import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { ButtonTemplateComponent } from '../../components/button-template/button-template.component';

import { QuillModule, QuillModules, QuillToolbarConfig } from 'ngx-quill';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { etipo_texto_sistema, IKeyValueType } from 'src/app/enums/Enums';
import { DateTimeBrPipe } from '../../pipes/date-br.pipe';
import { secUserAccount } from 'src/app/interfaces/security';
import { TextoSistemaEntity } from 'src/app/models/TextoSistema.data.model';
import { ToastrService } from 'ngx-toastr';
import { LoadingService } from 'src/app/services/loading.service';
import { TextFieldComponent } from '../../components/text-field/text-field.component';
import { Empresa } from 'src/app/models/Account.data.model';
import { environment } from 'src/environments/environment';
import { AccountService } from 'src/app/services/account.service';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { VariavesRegulamentoComponent } from "../../components/variaves-regulamento/variaves-regulamento.component";

@Component({
  selector: 'texto-sistema-page',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DialogModule,
    ButtonTemplateComponent,
    QuillModule,
    DateTimeBrPipe,
    TextFieldComponent,
    OverlayPanelModule,
    VariavesRegulamentoComponent
],
  templateUrl: './texto-sistema-display-form.component.html',
  styleUrl: './texto-sistema-display-form.component.css',
})
export class TextoSistemaDisplayFormComponent implements OnInit {
  @Input() set tipoTexto(value: etipo_texto_sistema) {
    this.tipo = value;
    
    this.currentUser = this.userService.currentUser;
    
    this.formTextoSistema.patchValue({
      texto_tipo: this.tipo,
    });
    
    this.getTexto(value);
  }
  @Input() areaCliente = false;
  @Input() readOnly = false; //se True, então não apresenta botão de edição, mesmo que seja para um Admin
  @Output() onClose = new EventEmitter<boolean>(false);
  
  
  currentEmpresa?: Empresa;
  
  showWindow = false;
  
  dtCreated?: Date;
  dtUpdated?: Date;
  
  isLoading = false;
  modoEditar = false;
  
  variaveisDisponiveis?: Map<string, IKeyValueType>;
  showListaVariaveis = false;
  varCopiada?: string;

  currentUser?: secUserAccount;

  quillToolbar: QuillToolbarConfig = [
    ['bold', 'italic'],
    ['link', 'blockquote', 'code-block', 'image'],
    [{ list: 'ordered' }, { list: 'bullet' }],
  ];

  modules: QuillModules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      ['blockquote', 'code-block'],

      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      [{ direction: 'rtl' }], // text direction

      [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],

      ['clean'], // remove formatting button

      ['link', 'image'], // link and image, video
    ],
  };

  nomePrograma = environment.APP_NAME;

  constructor(
    private readonly fb: FormBuilder,
    private readonly userService: UserService,
    private readonly utilitiesService: UtilitiesService,
    private readonly isLoadingService: LoadingService,
    private readonly accountService: AccountService,
    private readonly toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.currentUser = this.userService.currentUser;

    this.currentEmpresa = this.accountService.currentEmpresaLogada;

    this.isLoadingService.isLoading.subscribe((value) => {
      this.isLoading = value;
    });
  }

  platformId = inject(PLATFORM_ID);
  get isBrowser() {
    return isPlatformBrowser(this.platformId);
  }
  get isServer() {
    return isPlatformServer(this.platformId);
  }

  tipo?: etipo_texto_sistema;

  textoOriginalRegulamento?: string;

  requiredValidator = Validators.required;

  formTextoSistema = this.fb.group({
    texto_tipo: [this.tipo, Validators.required],
    texto_titulo: ['', Validators.required],
    texto_sistema: [''],
  });

  get fc() {
    return this.formTextoSistema.controls;
  }

  get fv() {
    return this.formTextoSistema.value;
  }

  get isAdmin() {
    if (
      !this.currentUser ||
      (this.currentUser &&
        this.currentUser.user_role &&
        this.currentUser.user_role !== 'ADMIN')
    )
      return false;
    return (
      this.currentUser &&
      this.currentUser.user_role &&
      this.currentUser?.user_role === 'ADMIN'
    );
  }

  async getTexto(tipo: etipo_texto_sistema) {

    const texto = await this.utilitiesService
      .getTextoSistemaByTipo(tipo)
      .catch((error) => {
        console.log(error);
      });

    if (texto) {
      // se for REGULAMENTO, então faz a tradução das variáveis antes de mandar para a página
      if (
        tipo === 'REGULAMENTO' &&
        texto &&
        texto.texto_sistema &&
        this.currentEmpresa
      ) {

        this.textoOriginalRegulamento = texto.texto_sistema;

        const _textoTraduzido = this.utilitiesService.traduzirTextoRegulamento(
          texto.texto_sistema,
          this.currentEmpresa,
          this.nomePrograma
        );

        if (_textoTraduzido)
          this.formTextoSistema.patchValue({
            texto_titulo: texto.texto_titulo,
            texto_sistema: _textoTraduzido,
          });
      } else {
        this.formTextoSistema.patchValue({
          texto_titulo: texto.texto_titulo,
          texto_sistema: texto.texto_sistema,
        });
      }

      this.dtCreated = texto.created_at;
      this.dtUpdated = texto.updated_at;

      this.showWindow = true;
    }

    if (!texto && this.isAdmin) {
      this.showWindow = true;
    }
  }

  saveTextoSistema() {
    if (this.fv.texto_tipo) {
      const _dados: TextoSistemaEntity = {
        texto_tipo: this.fv.texto_tipo,
        texto_titulo: this.fv.texto_titulo || undefined,
        texto_sistema: this.fv.texto_sistema || undefined,
      };

      this.utilitiesService
        .updateTextoSistema(_dados)
        .then((response) => {
          this.toastr.success('Texto atualizado com sucesso.', 'Sucesso!');

          console.log(response);
          this.showWindow = false;
          this.onClose.emit(true);
        })
        .catch((error) => {
          this.toastr.error('Erro ao atualizar texto.', 'Erro!');
          console.error(error);
        });
    }
  }

  ativarModoEditar() {
    // debugger;

    this.modoEditar = !this.modoEditar;

    if(this.tipo === 'REGULAMENTO') {

      if(this.modoEditar) {
        //alternar para o texto original com as variáveis
        this.formTextoSistema.patchValue({
          texto_sistema: this.textoOriginalRegulamento,
        });
      } else {

        if(this.fv.texto_sistema && this.currentEmpresa) {

          const _textoTraduzido = this.utilitiesService.traduzirTextoRegulamento(
            this.fv.texto_sistema,
            this.currentEmpresa,
            this.nomePrograma
          );
  
          if (_textoTraduzido)
            this.formTextoSistema.patchValue({
              texto_sistema: _textoTraduzido,
            });
        }
      }
    }
  }

  carregaVariaveis() {

    this.variaveisDisponiveis = undefined;
    if(this.currentEmpresa) {
      this.variaveisDisponiveis = this.utilitiesService.getVariaveisComValor(this.currentEmpresa)
    }
  }

  copyVariavel(varName: string) {
    if (varName) {
      navigator.clipboard.writeText(`[${varName}]`).then(
        () => {
          this.varCopiada = varName; // Mostrar mensagem de sucesso
        },
        (err) => {
          console.error('Erro ao tentar copiar o texto: ', err);
        }
      );
    }
  }

}
